
// Libraries
import React, {useRef} from 'react'
import {graphql} from 'gatsby'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import Button from '../components/button'
import {Link} from 'gatsby'

const OurPartnersPage = ({data}) => {
	const pageContent = data?.contentManagementOurPartners

	// scroll to components
	const partnersOntier = useRef(null)
	const scrollToPartnersOntier = () => partnersOntier.current.scrollIntoView()

	const partnersSweet = useRef(null)
	const scrollToPartnersSweet = () => partnersSweet.current.scrollIntoView()

	return (
		<Layout>
			<Seo title="Our Partners"/>
			<section className='generic-template partners-section'>
				<div className='partners-header'>
					<div className='container'>
						<div className='row'>
							<div className='logo-container'>
								<div className='main-logo'>
									<StaticImage src='../img/partners/cfc-main-logo.svg'
												 alt='Concerts for Carers logo'
												 placeholder={false}
												 className='cfc-logo' />
								</div>
								<div className='partners-logo'>
									<button onClick={scrollToPartnersOntier}>
										<GatsbyImage image={pageContent.image_partner2_logo_gatsby.childImageSharp.gatsbyImageData}
													 alt={`${pageContent?.partner2_name + ' Header Logo'}`}
													 className='logo' />
									</button>
									<button onClick={scrollToPartnersSweet}>
										<GatsbyImage image={pageContent.image_partner3_logo_gatsby.childImageSharp.gatsbyImageData}
													 alt={`${pageContent?.partner3_name + ' Header Logo'}`}
													 className='logo' />
									</button>
								</div>
							</div>

							<div className='partners-title'>
								<h1>{pageContent?.header_title}</h1>
								<div className='partners-text' dangerouslySetInnerHTML={{ __html: pageContent?.header_introduction}} />	
							</div>
						</div>
					</div>
				</div>

				<div className='partners-main-content'>
					<div className='container container-xl'>
						<div className='partners-card partners-card-ontier' ref={partnersOntier}>
							<div className='card-image'>
								<Link to={pageContent?.partner2_website} target='_blank'>
									<GatsbyImage image={pageContent.image_partner2_logo_mobile_header_gatsby.childImageSharp.gatsbyImageData}
												 alt={`${pageContent?.partner2_name + ' Content Image'}`}
												 className='desktop-background' />
								</Link>
								<div className='mobile-background'>
									<Link to={pageContent?.partner2_website} target='_blank'>
										<GatsbyImage image={pageContent.image_partner2_logo_gatsby.childImageSharp.gatsbyImageData}
													 alt={`${pageContent?.partner2_name + ' Mobile Header'}`} />
									</Link>
									<Link to={pageContent?.partner2_website} target='_blank'>
										<GatsbyImage image={pageContent.image_partner2_logo_mobile_gatsby.childImageSharp.gatsbyImageData}
													 alt={`${pageContent?.partner2_name + ' Mobile Logo'}`} />
									</Link>
								</div>
							</div>

							<div className='card-content' ref={partnersOntier}>
								<h2>{pageContent?.partner2_name}</h2>
								<div className="cms" dangerouslySetInnerHTML={{ __html: pageContent?.partner2_overview }} />
								<Button to={pageContent?.partner2_website} target='_blank' hollow colorEndeavour>
									Website
								</Button>
							</div>
						</div>
					</div>

					<div className='partners-banner'>
						<div className='banner-content right'/>
					</div>

					<div className='container container-xl'>
						<div className='partners-card' ref={partnersSweet}>
							<div className='card-image'>
								<Link to={pageContent?.partner3_website} target='_blank'>
									<GatsbyImage image={pageContent.image_partner3_logo_mobile_header_gatsby.childImageSharp.gatsbyImageData}
												 alt={`${pageContent?.partner3_name + ' Content Image'}`}
												 className='desktop-background' />
								</Link>
								<div className='mobile-background'>
									<Link to={pageContent?.partner3_website} target='_blank'>
										<GatsbyImage image={pageContent.image_partner3_logo_gatsby.childImageSharp.gatsbyImageData}
													 alt={`${pageContent?.partner3_name + ' Mobile Header'}`} />
									</Link>
									<Link to={pageContent?.partner3_website} target='_blank'>
										<GatsbyImage image={pageContent.image_partner3_logo_mobile_gatsby.childImageSharp.gatsbyImageData}
													 alt={`${pageContent?.partner3_name + ' Mobile Logo'}`} />
									</Link>
								</div>
							</div>
							<div className='card-content' ref={partnersSweet}>
								<h2>{pageContent?.partner3_name}</h2>
								<div className="cms" dangerouslySetInnerHTML={{ __html: pageContent?.partner3_overview }} />
								<Button to={pageContent?.partner3_website}  target='_blank' hollow colorEndeavour>
									Website
								</Button>
							</div>
						</div>
					</div>
					<div className='partners-banner partners-banner-bottom'/>
				</div>
			</section>
		</Layout>
	)
}

export default OurPartnersPage

export const query = graphql`
{
	contentManagementOurPartners {
		header_title
		header_introduction
		partner2_name
		partner3_name
		partner2_website
		partner3_website
		partner2_overview
		partner3_overview
		image_partner2_logo_gatsby {
			childImageSharp {
				gatsbyImageData(
					width: 350,
					aspectRatio: 1,
					layout: CONSTRAINED,
				)
			}
		}
		image_partner3_logo_gatsby {
			childImageSharp {
				gatsbyImageData(
					width: 350,
					aspectRatio: 1,
					layout: CONSTRAINED,
				)
			}
		}
		image_partner2_logo_mobile_gatsby {
			childImageSharp {
				gatsbyImageData(
					width: 1980,
					aspectRatio: 1.78,
					layout: CONSTRAINED,
				)
			}
		}
		image_partner3_logo_mobile_gatsby {
			childImageSharp {
				gatsbyImageData(
					width: 1980,
					aspectRatio: 1.78,
					layout: CONSTRAINED,
				)
			}
		}
		image_partner2_logo_mobile_header_gatsby {
			childImageSharp {
				gatsbyImageData(
					width: 1680,
					height: 1167,
					layout: CONSTRAINED,
				)
			}
		}
		image_partner3_logo_mobile_header_gatsby {
			childImageSharp {
				gatsbyImageData(
					width: 1680,
					height: 1167,
					layout: CONSTRAINED,
				)
			}
		}
	}
}
`